/* jshint esversion:8 */

// import {Auth} from 'aws-amplify';


import * as Queries from '../GraphQL/queries'
import * as Mutations from '../GraphQL/mutations'

import * as generics from './Generics'
import {countries} from './Generics/Countries'

// import {NotifMessage} from './generics';

import gql from 'graphql-tag';


// import { Link } from 'react-router-dom';


export const getQueries = (category) => {
    const get = 'get'+ category;
    const list = 'list' + category + 's';
    const deleter = 'delete' + category;

    const getter =  gql(Queries[get])
    const getterName= get;
    var lister= gql(Queries[list]);
    const listerName= list;

    if (category === 'Batch') 
    {
        lister = gql(
            `query ListBatchs(
                $filter: ModelBatchFilterInput
                $limit: Int
              ) {
                listBatchs(filter: $filter, limit: $limit) {
                  items {
                    id
                    name
                    product {
                      id
                      name
                      icon {
                        url
                        thumbnail
                      }
                      picture {
                        url
                        thumbnail
                      }
                      stages {
                        items {
                          id
                          name
                          description
                          order
                        }
                      }
                    }
                    createdAt
                    updatedAt
                    farm {
                      id
                      name
                      description
                      region
                      position {
                        longitude
                        latitude
                        altitude
                      }
                    }
                    creator {
                      id
                      name
                      role
                      languages
                      avatar {
                        url
                        thumbnail
                      }
                    }
                    comments (limit:100){
                      items {
                        id
                        comment
                        stage
                        company {
                          id
                          name
                          services
                          locations
                          createdAt
                          updatedAt
                        }
                        position {
                          longitude
                          latitude
                          altitude
                        }
                        createdAt
                        updatedAt
                        creator {
                          id
                          name
                          role
                        }
                        photos {
                          url
                          thumbnail
                        }
                      }
                    }
                    parent {
                      id
                      name
                      createdAt
                      updatedAt
                      creator {
                        id
                        name
                        role
                        languages
                        avatar {
                          url
                          thumbnail
                        }
                      }
                      comments {
                        items {
                          id
                          comment
                          stage
                          createdAt
                          updatedAt
                        }
                      }
                    }
                }
              }
            }`
        );
    }
    return ({
    getter: getter,
    getterName: getterName,
    lister: lister,
    listerName: listerName,
    deleter: deleter,
    })
};

export const getMutations = (category) => {
    const createName = 'create' + category;
    const updateName = 'update' + category;
    const deleteName = 'delete' + category;
    return (
    {
        creater: gql(Mutations[createName]),
        createrResultName: createName,
        updater: gql(Mutations[updateName]),
        updaterResultName: updateName,
        deleter: gql(Mutations[deleteName]),
        deleterResultName: deleteName,
    });
};

const flagsOptions = Object.entries(countries).map( ([countryCode, name],index) => ({
    text: name,
    value: countryCode,
    // icon: item.name.toLowerCase()
    }));

export const Views = {
    Batch: {
        plural:'Batches',
        icon:generics.getIconName('batch'),

        getEmptyNewElement: () => { return(
            {
                name: "",
                batchFarmId:0,
                batchCreatorId: generics.getCurrentUserName("test_user1") 
            })
        },
        fieldsStructure: {
            header: "Create a new batch:",
            groups:[
                {
                    name: "General",
                    fields: [
                        {label:'Name', name:"name"}
                    ]
                },
                {
                    name: "Farm",
                    fields: [
                        {label:'Farm', name:'batchFarmId', helperText: 'Select the origin farm', type:'querydropdown', queryname: 'listFarms',  query: gql(Queries.listFarms) },
                        {label:'Product', name:'batchProductId', helperText: 'Choose the product', type:'querydropdown', queryname: 'listProducts',  query: gql(Queries.listProducts) }
                    ]
                }
            ]
        }
    },
    Farm: {
        getEmptyNewElement: () => { return(
            {
                name: '',
                description: '',
                region: '',
                position: {
                    longitude: 0,
                    latitude: 0,
                    altitude: 0
                },
                farmCreatorId: generics.getCurrentUserName("test_user1") 
            }
        )},
        plural:'Farms',
        icon:generics.getIconName('farm'),
        fieldsStructure: {
            header: "Create a new farm",
            groups:[
                {
                    name: "General",
                    fields: [
                        {label:'Name', name:"name"},
                        {label:'Description', name:"description"},
                        {label:'Country, region', name:"region"},
                        
                    ]
                }
                ,
                {
                    name: "Location",
                    fields: [
                        {type:'location',name:"position",}
                    ]
                }
            ]
        }
    },
    
    User: {
        getEmptyNewElement : () => { return(
            {
                id: generics.getCurrentUserName("test_user1") ,
                name: "",
                role: "",
                languages: []
            }
        )},
        plural :'Users',
        icon:generics.getIconName('user'),
        fieldsStructure: {
            header: "Create a new user",
            groups:[
                {
                    name: "General",
                    fields: [{label:'Name', name:"name"}, {label:'Role', name:"role"}]
                },
                {
                    name: "Languages",
                    fields: [{label:'Languages', name:'languages', type:'multiselection', helperText: 'Choose all languages that the user speaks', options:[...flagsOptions]  }]
                },
                {
                    name: "Avatar",
                    fields: [{label:'Avatar', name:'avatar', helperText: 'Select an avatar or picture for the user', type:'picture'}]
                }
            ]
        },
    },

    Product: {
        getEmptyNewElement: () => (
        {
            id: '',
            name: ''
        }),
        plural :'Products',
        icon:generics.getIconName('product'),
        fieldsStructure:{
            header: "Create a new product",
            groups:[
                {
                    name: "General",
                    fields: [
                        {label:'Name', name:"name"}
                    ]
                },
                {
                    name: "Icon",
                    fields: [
                        {label:'Icon', name:'icon', helperText: 'Select an icon for the product', type:'picture', folder: 'public/icons/', metadata: {category:'icon'} },
                        {label:'Picture', name:'picture', helperText: 'Select a picture to show at the header of the timeline', type:'picture', folder: 'public/icons/', metadata: {category:'icon'} }
                    ]
                }
            ]
        }    
    },
    
    Comment: {
    getEmptyNewElement: () => (
        {
            id: '',
            comment: '',
            stage: '',
            position: {
                longitude: 0,
                latitude: 0,
                altitude: 0
            },
            commentCreatorId: generics.getCurrentUserName("test_user1") ,
        }),
        plural :'Comments',

        icon:generics.getIconName('comment'),
        fieldsStructure: {
            header: "Create a new comment:",
            groups:[
                {
                    name: "General",
                    fields: [
                        {label:'Batch', name:'commentBatchId', type:'querydropdown', queryname: 'listBatchs',  query: gql(Queries.listBatchs) },
                        {label:'Comment', name:"comment", helperText: 'Free text to describe the comment/event'},
                        {label:'Stage', name:"stage", helperText: 'Mention the stage, i.e growing, harcesting, transporting'},
                    ]
                },
                {
                    name: "Location",
                    fields: [
                        {
                            type:'location',
                            name:"position",
                        }
                    ]
                },
                {
                    name: "Picture(s)",
                    fields: [{label:'Pictures', name:'photos', helperText: 'Select picture(s) for the comment', folder: 'uploads/', type:'multi-picture' }]
                }
            ]
        }

    },
}
