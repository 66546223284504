

/* jshint esversion:8 */

import React from 'react';

import {Query} from 'react-apollo';

import {
    Input,
    InputLabel, 
    TextField, 
    FormControl, 
    Select, 
    MenuItem, 
    FormHelperText,
    Chip,
    ListItem,
    Button
} from '@material-ui/core';

import { ContentCopy } from 'mdi-material-ui';

import { green } from '@material-ui/core/colors';

import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { Link } from 'react-router-dom';

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    justifyCenter : {
        justifyContent: 'center',
        flexDirection: 'row',
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        //   position: 'absolute',
        //   width: 400,
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2),
          outline: 'none',
          margin: theme.spacing(2),
          flex: 1,
          minWidth: '60%',
        },
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        flex: 1,
        width: '100%',
    },
    hidden: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
}));

const theme = createMuiTheme({
    palette: {
        primary: green,
    },
}); 


export const ListItemLink = (props) => {
    return (<ListItem button component={Link} {...props} />);
}


export const MyInput = (props) => {
    const classes = useStyles();
    const {value, onChange} = props;
    // const [localValue, setValue] = React.useState(value? value : '');
    // console.log('MyInput: ' + name, value);

    function handleChange(event) {
        // setValue(event.target.value);
        onChange && onChange( event, {name: event.target.name, value: event.target.value, type: event.target.type } )
    }

    return (
        <ThemeProvider theme={theme}>
            <TextField
            className={classes.field}
            {...props}
            value={value}
            onChange={handleChange}
            />
        </ThemeProvider>
    );
};

export const Dropdown = (props) => {
    const classes = useStyles();
    const {name, helperText, options, onChange, multiple, value } = props;
    const [localValue, setValue] = React.useState( value? value :  multiple? [] : '');

    function handleChange(event) {
        console.log(event);
        setValue(event.target.value);
        onChange && onChange( event, {name: event.target.name, value: event.target.value, type: event.target.type } )
    }
    
    // function handleChangeMultiple(event) {
    //     const { options } = event.target;
    //     const value = [];
    //     for (let i = 0, l = options.length; i < l; i += 1) {
    //         if (options[i].selected) {
    //             value.push(options[i].value);
    //         }
    //     }
    //     setValue(value);
    //     onChange && onChange( event, {name: name, value: value, type: type } )
    // }




    const getSelectionRep = (value) => {
        const entry = options.find( (element) => ( element.value === value ) )
        if (entry)
            return <Chip variant="outlined" color="primary" key={value} label={entry.text} />
        return <Chip variant="outlined" color="primary"  key={value} label={value} />
    }

    const helperId = props.name + '-helper-id';
    const formControlStyle = clsx(classes.field, classes.formControl);
    return (
        <FormControl className={formControlStyle}>
            <InputLabel htmlFor={helperId}>{props.label}</InputLabel>
            <Select
            multiple= {(multiple)? true:false}
            value={localValue}
            onChange={handleChange}
            input={<Input name={name} id={helperId} />}
            renderValue={selected => (multiple)? selected.map( key => getSelectionRep(key)) : getSelectionRep(selected) }
            >
            {
                options.map( option => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem> )
            }
            </Select>
            {
                helperText && <FormHelperText>{helperText}</FormHelperText>
            }
            
      </FormControl>
    );
};



export const QueryDropdown = (props) => {
    const {query, queryname} = props;

    return (
        <Query query={query} 
            // fetchPolicy="network-only" 
            variables={{limit:100}}
            >
            {
                ({loading, error, data, refetch, networkStatus}) => {
                    if (networkStatus === 4) 
                        return <div>Refetching!</div>;
                    if (loading) 
                        return <div>Fetching</div>
                    if (error) {
                        console.error(error, data, refetch, networkStatus);
                        return <p>{error.message}</p>
                    }
                    // console.log(data);
                    const result = data[queryname].items;

                    const stateOptions = result.map( item => ({
                        text: item.name,
                        value: item.id,
                      }))
                    
                    return (
                        <Dropdown
                            {...props}
                          search
                          selection
                        options={stateOptions}
                        />
                    )
                }
            }
            </Query>
    );
}

export const CopyButton = (props) => {
    const {text} = props;
    const clickHanlder = (e) => {
        navigator.permissions.query({name: "clipboard-write"}).then(result => {
            console.log('Clipboard query result: ', result);
            if (result.state === "granted" || result.state === "prompt") {
              /* write to the clipboard now */
              navigator.clipboard.writeText(text).then( () => {
                /* clipboard successfully set */
                console.log("Successfully written to clipboard")
              }, function() {
                /* clipboard write failed */
                console.log("Failed to write to clipboard")
              });
            }
        });
    }
    return <Button onClick={clickHanlder} ><ContentCopy/> </Button>
}




