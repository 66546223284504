/* jshint esversion:8 */

import React from 'react';

import FormBuilder from './Generics/FormBuilder'

import {SettingsContext} from './context'
import { themes } from '../theme';

const datesIllustration = [
    {
        value: 'fuzzy',
        text: 'Show fuzzy dates (i.e. 2 days ago, 3 years ago)'
    },
    {
        value: 'short',
        text: 'Exact dates (i.e 2019-03-23)',
    },
    {
        value: 'localized',
        text: 'Localized dates (i.e 20. Dezember 2012)',
    },

];


const fieldsStructure = {
    header: "Settings",
    groups:[
        {
            name: "Appearance",
            fields: [
                {label:'Theme', name:'themeIndex', type:'selection', helperText: 'Choose on of the predefined themes', options: themes.map( (value, index) => ({value: index, text: value.name}) )  }
            ]
        },
        {
            name: "Presentation",
            fields: [
                {label:'Dates', name:'datesIllustration', type:'selection', helperText: 'Choose how you want to see the dates', options: datesIllustration.map( entry => ({value: entry.value, text: entry.text}) )  }
            ]
        },
    ]
}

export default function Settings () {
    const [settings, setSettings] = React.useContext(SettingsContext);

    console.log(settings);

    const updateNewElement = (event, source) => {
        console.log('updateNewElement', source);
        const field = source.name;
        let value = (source.type === 'number')? parseFloat(source.value) : source.value;

        setSettings( oldValue =>  ({ ...oldValue, [field]: value}));

    }
    return <FormBuilder 
    fieldsStructure={fieldsStructure} 
    onChange={updateNewElement}
    values={settings}
    />
}