/* jshint esversion:8 */

import React from 'react';


import {Paper, 
    Divider,
    Tooltip,
    Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { SatelliteVariant, GoogleMaps } from 'mdi-material-ui';



import {MapContext} from '../context'

import clsx from 'clsx';


import {MyInput, Dropdown, QueryDropdown} from '../Generics/Controls';
import {S3ImageUpload} from '../Generics/S3Uploader';


const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'center',
        flexDirection: 'row',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    justifyRight: {
        justifyContent: 'flex-end',
    },
    floatRight : {
        float: 'right',
        alignSelf: 'flex-end',
    },
    flexRowContainer : {
        width: '100%',
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        alignItems: 'stretch', 
    },
    paper: {
    //   position: 'absolute',
    //   width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      outline: 'none',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      flex: 1,
      width: '100%',
      minWidth: '100%',
    },
    field: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        flex: 1,
        width: '45%',
        minWidth: '45%',
    },
    progress: {
        margin: theme.spacing(2),
    },
  }));


  export const CoordsForm = (props) => {
    const classes = useStyles();
    const [values, setValues] = React.useState(props.value);
    
    
    const propagateValue = newValues => {
        const {onChange} = props;
        setValues( oldValues => ({...oldValues, ...newValues}));
        if (onChange)
            Object.entries(newValues).map( ([name, value]) => onChange(null, {name: props.name + "." + name, value: value, type: "number"  }))
    }

    const handleGPSClick = (evt) => {
        // evt.stopPropagation();
        evt.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition( 
                position => 
                    propagateValue({'longitude': position.coords['longitude'], 'latitude': position.coords['latitude'], 'altitude': position.coords['altitude']? position.coords['altitude']:0})
                    // ['longitude', 'latitude', 'altitude'].map( 
                    //     name => propagateValue(name, position.coords[name]? position.coords[name]:0 ))
                );
        } else {
            alert( "Geolocation is not supported by this browser.");
        } 
    };

    const handleInputChange = (event, source) => {
        const value = source.type === 'checkbox' ? source.checked : source.value;
        propagateValue({[source.name]: value});
    };
        
    console.log(values);
    const fields = [
    {
        label:'Longitude',
        type:'number',
        step:'0.00001',
        min:"-180",
        max:"180",
        name: "longitude",
    },
    {
        label:'Latitude',
        type:'number',
        step:'0.00001',
        min:"-85.05113",
        max:"85.05113",
        name:"latitude",
    },
    {
        label:'Altitude',
        type:'number',
        name:"altitude",
    }];

    return(
        <React.Fragment>
            { fields.map( field => <MyInput key={field.name} className={classes.field} onChange={handleInputChange} value={values[field.name]} {...field} /> ) }
            <div className={classes.field} >
                <Tooltip title="Get from GPS sensor" aria-label="Get from GPS sensor">
                    <Button onClick={handleGPSClick} >
                        <SatelliteVariant />
                    </Button>
                </Tooltip>
                <MapContext.Consumer>
                        {
                            (ctxt) =>{
                                // console.log(ctxt);
                                return(
                                    <Tooltip title='Get from actual map center' aria-label='Get from actual map center'> 
                                        <Button 
                                            onClick={ 
                                                (e) => {
                                                    e.preventDefault(); 
                                                    const {longitude, latitude} = ctxt.coordsGetter(); 
                                                    propagateValue({'longitude': longitude, 'latitude': latitude});
                                                }  
                                            } 
                                        >
                                            <GoogleMaps />
                                        </Button>
                                    </Tooltip>
                                );
                            } 
                        }    
                    </MapContext.Consumer>
            </div>
        </React.Fragment>
    );
}


export default function FormBuilder (props) {
    const {onChange, buttons, fieldsStructure, values} = props;
    const classes = useStyles();

    return (
        <form className={classes.container} noValidate autoComplete="off">
        {
            fieldsStructure.groups.map( group => {
                return(
                    <Paper key={group.name} className={clsx(classes.paper, classes.flexRowContainer)}>
                    {
                        group.fields.map( fieldOptions =>  {
                            if (fieldOptions.type) {
                                switch(fieldOptions.type) {
                                    case "selection": 
                                        return <Dropdown className={classes.field} placeholder={fieldOptions.label} key={fieldOptions.name} value={values[fieldOptions.name]} scrolling {...fieldOptions} onChange={onChange}/>;
                                    case "multiselection":
                                        return <Dropdown className={classes.field} placeholder={fieldOptions.label}  multiple key={fieldOptions.name} value={values[fieldOptions.name]}  scrolling {...fieldOptions} onChange={onChange}/>;
                                    case "querydropdown":
                                        return <QueryDropdown className={classes.field} key={fieldOptions.name} {...fieldOptions} value={values[fieldOptions.name]}  onChange={onChange}/>;
                                    case "picture":
                                    case "multi-picture":
                                        return <S3ImageUpload className={classes.field} multiple={fieldOptions.type === "multi-picture"? true : false} key={fieldOptions.name} value={values[fieldOptions.name]}  placeholder={fieldOptions.label} {...fieldOptions} onChange={onChange}/>;
                                    
                                    case "location":
                                        console.log(fieldOptions);
                                        console.log(values);
                                        return <CoordsForm key={fieldOptions.name} {...fieldOptions}  value={values[fieldOptions.name]}  onChange={onChange}/>;
                                    default:
                                        console.error("Unhandled type : " + fieldOptions.type );
                                        break; // Will return a generic input as below

                                } 
                            } 
                            return (<MyInput className={classes.field} key={fieldOptions.name} value={values[fieldOptions.name]}  onChange={onChange} {...fieldOptions} />)
                            
                        })
                    }
                    </Paper>
                )
            } )
        }
        {
            props.buttons &&
            <React.Fragment>
                <Divider/>
                <div className={clsx(classes.justifyRight, classes.flexRowContainer )} >
                    {buttons}
                </div>
            </React.Fragment>

        }
        </form>
    );
}