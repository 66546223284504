import React from 'react';
import QRCode from 'react-google-qrcode';

import { makeStyles } from '@material-ui/core/styles';

import {withDialogbuttonNTooltip} from './Dialog';


const useStyles = makeStyles(theme => ({
    qrcode: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '3em'
    }
}));


export default function ShowQRCode (props) {
    const {url, size, framed} = props;
    const classes = useStyles();
    return (
        <div className={classes.qrcode}>
            <a target="_blank" href={url}>
            <QRCode
            data={url}
            size={size? size: 130}
            framed={framed? true:false}
            />
            </a>
        </div>
    );
}


export const QrCodeDialog = withDialogbuttonNTooltip(ShowQRCode);