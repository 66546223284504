import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContentText,
  IconButton,
  Tooltip
 } from '@material-ui/core';

 import MuiDialogTitle from '@material-ui/core/DialogTitle';
 import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    justifyContent: 'center',
    justifyItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    justifyContent: 'center',
    display: 'block',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))(MuiDialogContent);

export const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomizedDialog = (props) => {
    // console.log('CustomDialog props:', props);
    const {open, title, text, onClose, actionButtons, closeButtonText, children, fullscreen,  ...restProps} = props;
    const theme = useTheme();
    const localFullScreen = fullscreen || useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullScreen={localFullScreen}
          scroll='paper'
           {...restProps}
        {...restProps}
        >
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            {title &&  title}
          </DialogTitle>
          <DialogContent dividers>
            {text && <DialogContentText>{text}</DialogContentText>}
            {children}
          </DialogContent>
          <DialogActions>
            {actionButtons}
            <Button onClick={onClose} color="primary">
              {closeButtonText? closeButtonText : 'Close'}
            </Button>
          </DialogActions>
        </Dialog>
    );
}

export default CustomizedDialog;


export const withDialogbuttonNTooltip = (WrappedComponent) => (props) => {
  const [visible, setVisible] = React.useState(false); 
  const {title, text, buttonProps, buttonIcon, actionButtons, closeButtonText, fullscreen, ...passThroughProps} = props;

  return (
      <React.Fragment>
      <Tooltip title={title} aria-label={title}>
      <Button {...buttonProps} onClick={ (e) => {e.stopPropagation(); setVisible(true)} } >
      {buttonIcon}
      </Button>
      </Tooltip>

      <CustomizedDialog 
          open={visible} 
          onClose={() => setVisible(false)} 
          aria-labelledby="form-dialog-title"
          title={title}
          text={text}
          actionButtons={actionButtons}
          closeButtonText={closeButtonText} 
          fullscreen={fullscreen}
          >
          <WrappedComponent {...passThroughProps} onClose={ () => setVisible(false)} />  
      </CustomizedDialog>
      </React.Fragment>

      
  )
}; 
