// eslint-disable
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    name
    role
    languages
    avatar {
      url
      thumbnail
    }
    createdAt
    updatedAt
    farms {
      items {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    comments {
      items {
        id
        comment
        stage
        company {
          id
          name
          services
          locations
          logo {
            url
            thumbnail
          }
          createdAt
          updatedAt
        }
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batch {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    store
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    name
    role
    languages
    avatar {
      url
      thumbnail
    }
    createdAt
    updatedAt
    farms {
      items {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    comments {
      items {
        id
        comment
        stage
        company {
          id
          name
          services
          locations
          logo {
            url
            thumbnail
          }
          createdAt
          updatedAt
        }
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batch {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    store
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    name
    role
    languages
    avatar {
      url
      thumbnail
    }
    createdAt
    updatedAt
    farms {
      items {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    comments {
      items {
        id
        comment
        stage
        company {
          id
          name
          services
          locations
          logo {
            url
            thumbnail
          }
          createdAt
          updatedAt
        }
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batch {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    store
  }
}
`;
export const createFarm = `mutation CreateFarm($input: CreateFarmInput!) {
  createFarm(input: $input) {
    id
    name
    description
    region
    position {
      longitude
      latitude
      altitude
    }
    createdAt
    updatedAt
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const updateFarm = `mutation UpdateFarm($input: UpdateFarmInput!) {
  updateFarm(input: $input) {
    id
    name
    description
    region
    position {
      longitude
      latitude
      altitude
    }
    createdAt
    updatedAt
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const deleteFarm = `mutation DeleteFarm($input: DeleteFarmInput!) {
  deleteFarm(input: $input) {
    id
    name
    description
    region
    position {
      longitude
      latitude
      altitude
    }
    createdAt
    updatedAt
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const createBatch = `mutation CreateBatch($input: CreateBatchInput!) {
  createBatch(input: $input) {
    id
    name
    product {
      id
      name
      icon {
        url
        thumbnail
      }
      picture {
        url
        thumbnail
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      stages {
        items {
          id
          name
          description
          order
          product {
            id
            name
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
    }
    createdAt
    updatedAt
    farm {
      id
      name
      description
      region
      position {
        longitude
        latitude
        altitude
      }
      createdAt
      updatedAt
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      photos {
        url
        thumbnail
      }
    }
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    comments {
      items {
        id
        comment
        stage
        company {
          id
          name
          services
          locations
          logo {
            url
            thumbnail
          }
          createdAt
          updatedAt
        }
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batch {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    parent {
      id
      name
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      createdAt
      updatedAt
      farm {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      parent {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      children {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
    }
    children {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const updateBatch = `mutation UpdateBatch($input: UpdateBatchInput!) {
  updateBatch(input: $input) {
    id
    name
    product {
      id
      name
      icon {
        url
        thumbnail
      }
      picture {
        url
        thumbnail
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      stages {
        items {
          id
          name
          description
          order
          product {
            id
            name
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
    }
    createdAt
    updatedAt
    farm {
      id
      name
      description
      region
      position {
        longitude
        latitude
        altitude
      }
      createdAt
      updatedAt
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      photos {
        url
        thumbnail
      }
    }
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    comments {
      items {
        id
        comment
        stage
        company {
          id
          name
          services
          locations
          logo {
            url
            thumbnail
          }
          createdAt
          updatedAt
        }
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batch {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    parent {
      id
      name
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      createdAt
      updatedAt
      farm {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      parent {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      children {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
    }
    children {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const deleteBatch = `mutation DeleteBatch($input: DeleteBatchInput!) {
  deleteBatch(input: $input) {
    id
    name
    product {
      id
      name
      icon {
        url
        thumbnail
      }
      picture {
        url
        thumbnail
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      stages {
        items {
          id
          name
          description
          order
          product {
            id
            name
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
    }
    createdAt
    updatedAt
    farm {
      id
      name
      description
      region
      position {
        longitude
        latitude
        altitude
      }
      createdAt
      updatedAt
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      photos {
        url
        thumbnail
      }
    }
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    comments {
      items {
        id
        comment
        stage
        company {
          id
          name
          services
          locations
          logo {
            url
            thumbnail
          }
          createdAt
          updatedAt
        }
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batch {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    parent {
      id
      name
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      createdAt
      updatedAt
      farm {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      parent {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      children {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
    }
    children {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const createComment = `mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    id
    comment
    stage
    company {
      id
      name
      services
      locations
      logo {
        url
        thumbnail
      }
      createdAt
      updatedAt
    }
    position {
      longitude
      latitude
      altitude
    }
    createdAt
    updatedAt
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    batch {
      id
      name
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      createdAt
      updatedAt
      farm {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      parent {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      children {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const updateComment = `mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    id
    comment
    stage
    company {
      id
      name
      services
      locations
      logo {
        url
        thumbnail
      }
      createdAt
      updatedAt
    }
    position {
      longitude
      latitude
      altitude
    }
    createdAt
    updatedAt
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    batch {
      id
      name
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      createdAt
      updatedAt
      farm {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      parent {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      children {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const deleteComment = `mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    id
    comment
    stage
    company {
      id
      name
      services
      locations
      logo {
        url
        thumbnail
      }
      createdAt
      updatedAt
    }
    position {
      longitude
      latitude
      altitude
    }
    createdAt
    updatedAt
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    batch {
      id
      name
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      createdAt
      updatedAt
      farm {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      parent {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      children {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const createProduct = `mutation CreateProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    id
    name
    icon {
      url
      thumbnail
    }
    picture {
      url
      thumbnail
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    stages {
      items {
        id
        name
        description
        order
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
  }
}
`;
export const updateProduct = `mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
    name
    icon {
      url
      thumbnail
    }
    picture {
      url
      thumbnail
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    stages {
      items {
        id
        name
        description
        order
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
  }
}
`;
export const deleteProduct = `mutation DeleteProduct($input: DeleteProductInput!) {
  deleteProduct(input: $input) {
    id
    name
    icon {
      url
      thumbnail
    }
    picture {
      url
      thumbnail
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    stages {
      items {
        id
        name
        description
        order
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
  }
}
`;
export const createStage = `mutation CreateStage($input: CreateStageInput!) {
  createStage(input: $input) {
    id
    name
    description
    order
    product {
      id
      name
      icon {
        url
        thumbnail
      }
      picture {
        url
        thumbnail
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      stages {
        items {
          id
          name
          description
          order
          product {
            id
            name
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const updateStage = `mutation UpdateStage($input: UpdateStageInput!) {
  updateStage(input: $input) {
    id
    name
    description
    order
    product {
      id
      name
      icon {
        url
        thumbnail
      }
      picture {
        url
        thumbnail
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      stages {
        items {
          id
          name
          description
          order
          product {
            id
            name
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const deleteStage = `mutation DeleteStage($input: DeleteStageInput!) {
  deleteStage(input: $input) {
    id
    name
    description
    order
    product {
      id
      name
      icon {
        url
        thumbnail
      }
      picture {
        url
        thumbnail
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      stages {
        items {
          id
          name
          description
          order
          product {
            id
            name
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const createCompany = `mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    id
    name
    services
    locations
    logo {
      url
      thumbnail
    }
    createdAt
    updatedAt
  }
}
`;
export const updateCompany = `mutation UpdateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    id
    name
    services
    locations
    logo {
      url
      thumbnail
    }
    createdAt
    updatedAt
  }
}
`;
export const deleteCompany = `mutation DeleteCompany($input: DeleteCompanyInput!) {
  deleteCompany(input: $input) {
    id
    name
    services
    locations
    logo {
      url
      thumbnail
    }
    createdAt
    updatedAt
  }
}
`;
