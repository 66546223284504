/*jshint esversion:8*/

import React, {useState} from 'react';
 
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexGrow: '1'
    },
    smallerFont: {
        fontSize: 'small',
    },
    relative: {
        position: 'relative !important' 
    }
}));

export const MapContainer = (props) => {
    const {context} = props;
    const classes = useStyles();
    // console.log(props);

    const mapStyle = clsx(classes.relative, classes.smallerFont); 

    const getBounds = (markers) => {
        var bounds = new props.google.maps.LatLngBounds();
        if (markers){
            for (var i = 0; i < markers.length; i++) {
                bounds.extend(markers[i]);
            }
        }
        return bounds;
    };

    const [bounds, setBounds] = useState(getBounds(context.zoomedMarkers));
    const [markers, setMarkers] = useState(context.markers);
    // const [center, setCenter] = useState({lat: context.viewport.latitude,lng: context.viewport.longitude});
    
    const onMarkerClick = () => {
        // ..
    };

    const onMouseoverMarker = () => {
        // ..
    };



    const onContextChange = () => {
        setBounds( getBounds(context.zoomedMarkers) );
        setMarkers(context.markers);
    };

    const centerMoved = (mapProps, map) => {
        context.updateViewport( {longitude: map.center.lng(), latitude: map.center.lat(), zoom: map.zoom } )
    };



    // console.log(context);
    context.addListener && context.addListener(onContextChange);
    const {longitude,latitude,zoom} = context.viewport;

    // console.log("Drawing the map");
    // console.log(markers, bounds);

    return(
        <Map google={props.google} 
        style={{width: '100%', height: '100%', position: 'relative', minHeight: '80vh' }}
        initialCenter={{
            lat: latitude,
            lng: longitude
        }}
        zoom={zoom}
        maxZoom={10}
        bounds={bounds}

        onDragend={centerMoved}
        className={mapStyle}

        >
        {
            markers && markers.map( marker => 
                <Marker 
                key={marker.id} title={marker.text} 
                position={{lat: marker.lat, lng: marker.lng }}  
                onClick={onMarkerClick}
                onMouseover={onMouseoverMarker}
                /> )
        }
            {/* <InfoWindow onClose={this.onInfoWindowClose}>
                <div>
                <h1>{this.state.selectedPlace.name}</h1>
                </div>
            </InfoWindow> */}
        </Map>            

    );

}

 
const API_KEY='AIzaSyBMj79zwCPcJoco9yMXrzy31v_2PNnN_B4'

export default GoogleApiWrapper({
  apiKey: API_KEY
})(MapContainer)






