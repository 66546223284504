
export const themes = [
  {
    name: 'Elegant',
    palette: {
      primary: {
        main: '#000000',
        light: '#cccccc',
      },
      secondary: {
        main: '#006F74',
      },
    },
  },
  {
    name: 'Color 1',
    "palette": {
      primary: {
        //         // light: will be calculated from palette.primary.main,
        main: '#C72C62',
        light: '#EB738D',
      },
      secondary: {
        main: '#B0AB32',
      },
    },
  },
  {
    name: 'Dark Green',
    "palette": {
      primary: {
        //         // light: will be calculated from palette.primary.main,
        main: '#004d40',
        light: '#e0f2f1',
      },
      secondary: {
        main: '#bf360c',
      },
    },
  },
  {
    name: 'Power Apps',
    "palette": {
      primary: {
        //         // light: will be calculated from palette.primary.main,
        main: '#3b2d51',
        light: '#e1eae0'
      },
      secondary: {
        main: '#2a2a2a',
      }
    }
  },
  {
    name: 'Olive',
    palette: {
      primary: {
        main: '#c5e1a5',
      },
      secondary: {
        main: '#fb8c00',
      },
    },
  },
  {
    name: 'lightblue',
    palette: {
      primary: {
        main: '#FF5A1D',
        light: '#773306',
      },
      secondary: {
        main: '#4DA7D7',
      },
    },
  },

];



