/* jshint esversion:9 */

import React, {useState} from 'react';

import {Query, Mutation} from 'react-apollo';

import {
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    IconButton,
    ListSubheader,
    Tooltip,
    LinearProgress,
    Chip
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Magnify, Refresh, PlusCircleOutline, ContentCopy, Pencil, Delete, ChevronUp, ChevronDown, Qrcode } from 'mdi-material-ui';

import {Views, getMutations, getQueries} from '../views';

import { Link } from 'react-router-dom';

import {MapContext} from '../context';

import * as generics from '../Generics';
import {countries} from '../Generics/Countries';

import {WithDialogMutationForm} from './MutationForm';

import {QrCodeDialog} from '../Generics/QrCode';


  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      //maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      flexGrow: 1,
    },
    inline: {
      display: 'inline',
    },
    chip: {
        margin: theme.spacing(1),
    },
    width100 :{
        width: '100%',
    },
    flexColumnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // alignContent: 'space-around',
    },
    flexRowContainer : {
        // width: '100%',
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        alignItems: 'stretch',
        justifyContent: 'space-around', 
        paddingTop: theme.spacing(2),
        paddingBotton: theme.spacing(2),
    },
    bottomBorder: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.light,
    },
    topBorder :{
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.primary.light,
    },
    coloredBackground: {
        background: theme.palette.primary.dark,
        color: '#ffffff',
    },
    lightBackground: {
        background: theme.palette.primary.light,
        color: '#000000',
    },

    itemSelected: {
        borderLeftWidth: '4px',
        borderLeftStyle: 'solid',
        borderLeftColor: theme.palette.primary.dark,
    },
    noPadding : {
        padding: '0px',
    },
    smallerFont: {
        fontSize: 'smaller',
    },
    imgSize1em: {
        width: '1em',
        height: '1em',
        paddingRight: '2em',
    },
    justifyRight : {
        justifyContent: 'flex-end',
    },
    floatRight : {
        float: 'right',
        alignSelf: 'flex-end',
    },
    floatLeft : {
        float: 'left',
        alignSelf: 'flex-start',
    },
    
    leftPadding: {
        paddingLeft: theme.spacing(4),
      },
    boxShadow : {
        // background: '#FFFFFF',
        boxShadow: '0px 0px 5px 0px  #00000077',
    },
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    pointer: {
        cursor: 'pointer',
        '&:hover': {
            background: '#ffffff',
         },
    },
  }));



const fillMarkerCoordinates = item => {return({id: item.id , text: item.name,  lng: item.position.longitude, lat: item.position.latitude})};

const getUrl = (item, single) => {
    // console.log(generics.getRouteUrl(item.__typename));
    const specific =  single? ((item.id)? '/' +item.id:'' ) : '' ; 
    return (generics.getRouteUrl(item.__typename) +  specific) ;
};

const GetItemRepresentation = (props) => {
    const {name, item, as} = props;
    const classes = useStyles();

    const getFields = () => {
        if (name) {
            switch (name){
                case "languages":
                    return {
                        avatar: <Avatar>{generics.getIcon('language')}</Avatar>,
                        label: item.map( ln => countries[[ln]] ).join(', ') ,
                    }
                case "photos":
                    if (item.length > 0)
                        return {
                            avatar:  <Avatar src={generics.getS3Url(item[0])} size={'mini'} />,
                            label: item.length + ' photos' ,
                        }
                    return null;
                default:
                    break;
            }
        }
        switch (item.__typename) {
            case "Batch":
                return {
                        avatar: (item.product && item.product.icon)? <Avatar src={generics.getS3Url(item.product.icon)} size={'mini'} /> : <Avatar>{generics.getIcon('batch')}</Avatar> ,
                        label: item.name, 
                    }
            case "User":
                return {
                        avatar: <Avatar>{generics.getIcon('user')}</Avatar>,
                        label: item.name,
                    }
            case "Farm":
                return {
                        avatar: <Avatar>{generics.getIcon('farm')}</Avatar>,
                        label: item.name,
                    }
            case "Position":
                return {
                        avatar: <Avatar>{generics.getIcon('position')}</Avatar>,
                        label: item.longitude + ",  " + item.latitude + " ", 
                    }
            case "Product":
                return {
                        avatar: <Avatar src={generics.getS3Url(item.icon)} size={'mini'} />,
                        label: item.name,
                    }
            case "Comment":
                return {
                    avatar: <Avatar src={generics.getS3Url(item.icon)} size={'mini'} />,
                    label: item.stage,
                }
            case "ModelBatchConnection":
            case "ModelCommentConnection":
            case "ModelFarmConnection":
            case "":
            case "batches":
            case "S3Object":
                console.log('Ignoring the field ' + item.__typename + ' while rendering');
                return null;
            default:
                return {
                    avatar: <Avatar>{generics.getIcon('alert')}</Avatar>,
                    label: 'Unhandled object '+ name + ' of type '+ item.__typename, 
                }
        }
    }

    const fields = getFields(); 
    if (fields) {
        switch (as){
            case 'chip': 
            return <Chip
                    size='small'
                    avatar={fields.avatar}
                    label={fields.label}
                    clickable
                    className={classes.chip}
                    // color="primary"
                    // onDelete={handleDelete}
                    // deleteIcon={<DoneIcon />}
                    variant="outlined"
                /> 
            case 'listitem':
                return <React.Fragment>
                    <ListItemAvatar className={classes.imgSize1em}>{fields.avatar}</ListItemAvatar>
                    <ListItemText primary={fields.label} /> 
                </React.Fragment>
            case 'card':
            default:
                return null; 
        } 
    }
    return null;
};

const childObjectsFilter = ([, child]) => {
    // console.log(name,child);
    if (Array.isArray(child))
        return true;
    if (child && typeof child === 'object')
    {
        switch (child.__typename) {
            case "ModelBatchConnection":
            case "ModelCommentConnection":
            case "ModelFarmConnection":
                return true;
            default:
                // return <li key={name}>{getListItemForObject(name, child, depth + 1, maxDepth)}</li> ;
                return true ;
        }
    }
    return false;
}

const CreateNew = (props) => {
    const {name, variables} = props; 
    const {creater, createrResultName} = getMutations(name);
    const {lister, listerName} = getQueries(name);
    return <WithDialogMutationForm
        title={'Create a new ' + name}
        buttonIcon={<PlusCircleOutline />} 
        mutation={creater} 
        mutationResultName={createrResultName} 
        query={lister}
        queryName={listerName}
        variables={variables}
        {...Views[name]}
        {...props}
        initialValues={Views[name].getEmptyNewElement()}
        />;
}

const CopyElement = (props) => {
    const {name, variables, initialValues} = props; 
    const {creater, createrResultName} = getMutations(name);
    const {lister, listerName} = getQueries(name);
    return <WithDialogMutationForm
        title='Create a copy'
        buttonIcon={<ContentCopy />} 
        mutation={creater} 
        mutationResultName={createrResultName} 
        query={lister}
        queryName={listerName}
        variables={variables}
        {...Views[name]}
        {...props}
        initialValues={initialValues}
        />;
}

const getCopyWithout = (item, props) => {
    let copy = {...item};
    props.forEach( prop => delete copy[prop] );
    return copy;
}

const DeleteItem = (props) => {
    const classes = useStyles();
    const {item} = props; 
    const name = item.__typename;
    const {deleter} = getMutations(name);
    const {lister, listerName} = getQueries(name);
    const handleDelete = (deleteCallback) => {
        deleteCallback({
            variables: {
                input: {
                    id: item.id
                }
            },
            optimisticResponse: () => ({
                [listerName]: {
                    // // This type must match the return type of
                    // //the query below (listPosts)
                    // __typename: 'ModelPostConnection',
                    // id: this.props.id,
                    // title: this.props.title,
                    // body: this.props.body,
                    // createdAt: this.props.createdAt
                    ...item
                }
            }),
            update: (cache) => {
                const query = lister;

                // Read query from cache
                const data = cache.readQuery({ query });

                // Add updated postsList to the cache copy
                data[listerName].items = [
                    ...data[listerName].items.filter(it =>
                     it.id !== item.id)
                ];

                //Overwrite the cache with the new results
                cache.writeQuery({ query, data });
            }
        })
    }

    return (
        <Mutation mutation={deleter}>
            {(deleteCallback) => {
                return (
                    <Tooltip title="Delete" aria-label="Delete">
                        <IconButton size='small' aria-label="Delete" onClick={
                        () => handleDelete(deleteCallback)} className={clsx(classes.fab, classes.noPadding)} >
                            <Delete  deleter={deleter} id={item.id} />
                        </IconButton>
                    </Tooltip>
                );
            }}
        </Mutation>
    )
    
}



export default function Listing (props)  {
    const classes = useStyles();
    const titleStyle = clsx(classes.coloredBackground, classes.width100, classes.boxShadow, classes.flexColumnContainer, classes.noPadding);

    const {name, hideHeaders} = props;
    const [selection, setSelection] = useState(null);

    let variables = {
        limit: 100,
    };

    const {id} = props.match.params;

    if (id)
    {
        variables['filter'] = {id: {eq: id}}
    }
    
    const { 
        // getter, 
        // getterName, 
        lister, 
        listerName,
    } = getQueries(name);

    const query = lister;

    console.log(lister, listerName);
    

    // function safeStringify (value) {
    //     const seen = new Set()
    //     return JSON.stringify(value, (k, v) => {
    //       if (seen.has(v)) { return '...' }
    //       if (typeof v === 'object') { seen.add(v) }
    //       return v
    //     })
    //   }

    return (
        <MapContext.Consumer>{
            (ctxt) =>{
                // console.log(ctxt);
                const handleSelection = (item) => {
                    if (item && item.position)
                        ctxt.markersZoomer([fillMarkerCoordinates(item)]);
                    else 
                        ctxt.markersZoomer(null);

                    setSelection(item);
                }
                return(
                    <Query query={query} 
                    // fetchPolicy="network-only" 
                    variables={{...variables}}
                    >
                    {
                        ({loading, error, data, refetch, networkStatus}) => {
                            if (error) {
                                console.error(error, data, refetch, networkStatus);
                                return <p>{error.message}</p>
                            }
                            console.log(variables);
                            console.log(data);
                            
                            const result =  (data && data[listerName] && data[listerName].items)? 
                            data[listerName].items.filter( item => { if(item) return true; else return false;}  ) 
                                : [] ;
                            console.log('result', result);

                            var markers = [];
                            result && result.forEach(item => {
                                item.position && markers.push(fillMarkerCoordinates(item));
                            });

                            ctxt.markersSetter(markers);
                            ctxt.markersZoomer( (selection && selection.position)?  [fillMarkerCoordinates(selection)] : null );

                            return (
                                <div className={classes.root}>
                                    {
                                        (loading || (networkStatus === 4)) &&  
                                        <div className={clsx(classes.root, classes.flexRowContainer, classes.width100)}>
                                            <LinearProgress className={classes.width100} />
                                        </div>
                                    }
                                    <List 
                                        // component="nav"
                                        className={classes.flexColumnContainer}
                                        aria-labelledby="nested-list-subheader"
                                        subheader={
                                            !hideHeaders && 
                                            <ListSubheader component="div" className={titleStyle} >
                                                <div className={classes.flexRowContainer} >
                                                    {'Showing ' + result.length + ' ' +  (result.length === 1? name : Views[name].plural) }
                                                    <div className={classes.floatRight} >
                                                    <Tooltip title="Refetch" aria-label="Refetch">
                                                        <Button size='small' aria-label="Refresh" color='inherit' onClick={() => refetch()}>
                                                            <Refresh />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Reset Selection & Zoom out" aria-label="Reset Selection & zoom out">
                                                        <Button size='small' aria-label="Zoom-Out" color='inherit'  onClick={() => handleSelection(null) } >
                                                            <Magnify />
                                                        </Button>
                                                    </Tooltip>
                                                    {
                                                        generics.isUserAuthenticated() && <CreateNew {...props} variables={{...variables}} buttonProps={{size:'small', color: 'inherit'}} />
                                                    }
                                                    
                                                    </div>
                                                </div>
                                            </ListSubheader>
                                        }
                                    >
                                    { 
                                        result.map((item) => {
                                            const selected = selection===item; 
                                            const filteredChildren = Object.entries( item ).filter(childObjectsFilter);

                                            // if (selected && item.__typename === "Batch")
                                            // {
                                            //     return <TimeLine key={item.id} batch={item} />
                                            // }
                                            return ( 
                                                <ListItem
                                                    key={item.id}
                                                    className={ clsx(classes.flexRowContainer,  classes.width100, !selected && classes.pointer,  !selected && classes.lightBackground ) }
                                                
                                                                                                    >
                                                    <div className={ clsx(classes.flexRowContainer, classes.bottomBorder, classes.width100 ) } onClick={(e) => {
                                                            e.stopPropagation(); 
                                                            handleSelection( (selected)? null: item ) ;
                                                        }}
                                                    >
                                                    <GetItemRepresentation item={item} as='listitem'/>
                                                    {selected && <ChevronUp className={classes.floatRight} /> }
                                                    {!selected && <ChevronDown className={classes.floatRight}/>}
                                                    </div>
                                                    {   selected &&  
                                                        <div className={clsx(classes.flexColumnContainer, classes.smallerFont)}>
                                                        {
                                                            filteredChildren.length > 0 && 
                                                            <div className={clsx(classes.leftPadding, classes.flexRowContainer) }>
                                                            { 
                                                                Object.entries( item ).filter(childObjectsFilter).map( ([key, child]) => {
                                                                    console.log('drawing sub item :', key);
                                                                    return (
                                                                        <Link 
                                                                            className={ clsx(classes.flexRowContainer, classes.noPadding) } 
                                                                            key={child.id? child.id : item.id+'.'+key } 
                                                                            to={ getUrl(child, true)   }    
                                                                        >
                                                                        <GetItemRepresentation name={key} item={child} as='chip'/>
                                                                        </Link>
                                                                    );
                                                                    
                                                                    }
                                                                )
                                                            }
                                                            </div>
                                                        }
                                                        <div className={clsx(classes.flexRowContainer, classes.justifyRight) } >  
                                                        {
                                                            item.__typename === "Batch" && 
                                                            <QrCodeDialog url={generics.getPublicUrl(item.id)} 
                                                                text={generics.getPublicUrl(item.id)} 
                                                                size={200} buttonProps={{size:'small'}} 
                                                                title='Show URL' buttonIcon={<Qrcode />}  
                                                            />
                                                        }
                                                        
                                                        {
                                                            generics.isUserAuthenticated() &&
                                                            <React.Fragment>
                                                                <CopyElement {...props} variables={{...variables}} buttonProps={{size:'small'}} initialValues={getCopyWithout(item, ['id'])} />
                                                                <Tooltip title="Edit" aria-label="Edit">
                                                                    <IconButton size='small' aria-label="Edit" className={clsx(classes.fab, classes.noPadding)} >
                                                                        <Pencil />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <DeleteItem  item={item}  />

                                                            </React.Fragment>   
                                                        } 
                                                        </div>
                                                        </div>
                                                    }
                                                </ListItem>
                                            );
                                            
                                        })
                                        
                                    }
                            </List>
                        </div>
                            );
                        }
                    }
                    </Query>
                )
            }
        }
        </MapContext.Consumer>
    );
    
};
