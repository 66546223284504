

import React from 'react';


import {
    InputLabel, 
    CircularProgress,
    Button,
    Tooltip
} from '@material-ui/core';

import { ImageMultiple, MinusCircleOutline } from 'mdi-material-ui';


import awsconfig from '../../aws-exports';
import { Storage } from 'aws-amplify';


import { makeStyles } from '@material-ui/core/styles';


import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      // alignItems: 'stretch',
      flexGrow: 1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    button: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(2),
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      maxWidth: '60px',
      maxHeight: '60px',
    },
    width100 :{
        width: '100%',
    },
    flexColumnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // alignContent: 'space-around',
    },
    flexRowContainer : {
        // width: '100%',
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        alignItems: 'stretch',
        justifyContent: 'space-around', 
    },
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        flex: 1,
        // width: '100%',
    },
    hidden: {
        display: 'none',
    },
    image : {
        objectFit: 'contain',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fileElement: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(2),
      outline: 'none',
      //   margin: theme.spacing(2),
      // flex: 1,
      height: '120px',
      width: '120px',
      // flexShrink: 1,
      // minWidth: '25%',
      position: 'relative',
      display: 'inline-block', /* added */
      overflow: 'hidden', /* added */
      '& img': {
        '&:hover': {
          opacity: '0.5',
        }
      },
    },
    coverParent: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: '0',
      bottom: '0',
      right: '0',
      textAlign: 'center',
      color: 'black',
    },
    overlay : {
      // top: '-100%',
      opacity: '0',
      '&:hover' : {
          opacity: '1', /* added */
          top: '0', /* added */
          zIndex: '500',
          backgroundColor: '#0000007f',
          color: 'white',
      } 
    },
    iconbutton: {
      fontSize : '50px',
      top: '50%',
      left: '50%',
      position: 'absolute',
      transform: 'translate(-50%,-50%)',
    },
    link: {
      cursor: 'pointer',
    },

}));


const ImgControl = (props) => {
    const classes = useStyles();
    const {entry, onRemove} = props;
    const {url, s3key, progress} = entry;

    //console.log(url, s3key, onRemove);

    
    if (progress < 100) 
      return <CircularProgress variant="determinate" value={progress} size={88}/>
    else if (url || s3key)
      return (
        <React.Fragment>
          <img className={ clsx(classes.field, classes.image)} src={url? url : s3key} alt={url} />
          <Button className={clsx(classes.overlay, classes.coverParent)} onClick={onRemove} >
            <MinusCircleOutline  className={classes.iconbutton} />
          </Button>
        </React.Fragment>
      );
    return null;
};

// 3. NEW: Create an S3ImageUpload component
export const S3ImageUpload = (props) => {
    const S3_BUCKET_NAME = awsconfig.aws_user_files_s3_bucket;
    const classes = useStyles();

    const {name, label, multiple} = props;
    // console.log(props);

    const [entries, setEntries] = React.useState([]);

    // const handleDelete = async () => {
    //     console.log('deleteing the file ', uploadedFile);
    //     Storage.remove(uploadedFile)
    //         .then(result => {
    //             console.log("Deletion result: ",  result);
    //             setUploadedFile(null);
    //         })
    //         .catch(err => console.log("Error while deleting: " , err));
    // }

    const onRemove = (e, index) => {
      console.log ('Removing image with index ' + index);
      setEntries( oldValue =>  {
        const ret = [...oldValue]; 
        ret[index].ignore = true; 
        if (props.onChange)
        {
          props.onChange(e,  {type:props.type, name:props.name,  value: multiple? ret.filter( item => item.ignore !== true ).map(entry=> entry.s3key ) : ret[0].s3key } )  ;
        }
        return ret; 
      });

    }

    const onChange = async (e) => {
        // console.log(e.target.files);
        // console.log(file);
        const ts = new Date();

        Array.from(e.target.files).forEach( file => {

          const fileName = ts.toISOString().replace(/[^1234567890]/g, '_').substr(0,19) + "_" + file.name ;

          // console.log('Uploading ' + file.name + ' to ' + this.props.folder + ' as ' + fileName );

          var index = 0;

          const fileEntry = {file:file, url: URL.createObjectURL(file), progress: 0, s3key: null}
  
          setEntries( oldValue => { index = oldValue.length; console.log(index); return [...oldValue, fileEntry ]; } );
  
          Storage.put(
              fileName, 
              file, 
              {
                  customPrefix: { public: props.folder },
                  metadata: { ...props.metadata },
                  progressCallback(progress) {
                      setEntries( oldValue =>  {const ret = [...oldValue]; ret[index].progress = progress.loaded / progress.total * 100; return ret;});
                  },
              }
          ).then( result => {
            // console.log('Uploaded file: ', result);
            var url = 'https://' +S3_BUCKET_NAME+'.s3.amazonaws.com/' + `${props.folder}/${result.key}`.replace(/\/\//g, '/');
            var resized_url = 'https://' +S3_BUCKET_NAME+'.s3.amazonaws.com/' + `${props.folder}/resized/${result.key}`.replace(/\/\//g, '/');

            setEntries( oldValue =>  {
                const ret = [...oldValue]; 
                ret[index].s3key = {url: url, thumbnail: resized_url }; 
                if (props.onChange)
                {
                  props.onChange(e,  {type:props.type, name:props.name,  value: multiple? ret.filter( item => item.ignore !== true ).map(entry=> entry.s3key ) : ret[0].s3key } )  ;
                }
                return ret; 
              });
          } )
          .catch(err => {
              console.log('Failed to upload ', err);
              setEntries( oldValue =>  {const ret = [...oldValue]; ret[index].ignore = true; return ret; });
          });
        })
    };

    const helperId = name + '-helper-id';

    return (
        <div className={classes.flexRowContainer}>
            {
                entries && entries.map( (entry,index) => {
                  if (entry.ignore)
                    return null;
                  return (
                    <div key={"SelectedImage_"+ index} className={classes.fileElement} >
                        <ImgControl entry={entry} onRemove={(e) => onRemove(e,index)} />
                    </div>
                  );
                })
            }
            <Tooltip title={label} aria-label={label}> 
              <div className={classes.fileElement} >
                <input
                    className={clsx(classes.hidden, classes.field)} 
                    name={name} id={helperId} 
                    type="file" accept='image/*' onChange={onChange}
                    multiple={ multiple? true: false}
                />
                <InputLabel htmlFor={helperId} className={classes.field} >
                  <div className={clsx(classes.link, classes.coverParent)} >
                    <ImageMultiple  className={classes.iconbutton} />
                  </div>
                </InputLabel> 
              </div>
            </Tooltip>
        </div>
    );      
}

