/* jshint esversion:9 */

import React, {useState} from 'react';

import {Mutation} from 'react-apollo';

import {withDialogbuttonNTooltip} from '../Generics/Dialog';

import {
    // Dialog, 
    // DialogContent,
    // DialogContentText,
    // DialogTitle,
    Button,
    CircularProgress,
    Tooltip,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { Upload } from 'mdi-material-ui';


import FormBuilder from '../Generics/FormBuilder'


const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'center',
        flexDirection: 'row',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    justifyRight: {
        justifyContent: 'flex-end',
    },
    floatRight : {
        float: 'right',
        alignSelf: 'flex-end',
    },
    flexRowContainer : {
        width: '100%',
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        alignItems: 'stretch', 
    },
    paper: {
    //   position: 'absolute',
    //   width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      outline: 'none',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      flex: 1,
      width: '100%',
      minWidth: '100%',
    },
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        flex: 1,
        width: '100%',
    },
    progress: {
        margin: theme.spacing(2),
    },
  }));


export const MutationForm = (props) => {
    console.log(props);
    const {mutation, mutationResultName, onClose} = props;
    const { query, queryName, variables} = props;
    const {fieldsStructure, initialValues} = props;

    const classes = useStyles();

    const [values, setValues] = useState(initialValues);

    const updateNewElement = (event, source) => {
        console.log('updateNewElement', source);
        const field = source.name;
        const fields = field.split(".");
        let value = (source.type === 'number')? parseFloat(source.value) : source.value;
        switch (fields.length) {
            case 1:
                setValues ( oldValues => ({
                    ...oldValues,
                    [field]: value
                }));
                break;
            case 2:
                setValues( oldValues => ({
                        ...oldValues,
                        [fields[0]]: {
                            ...oldValues[fields[0]],
                            [fields[1]]: value
                        }
                    }));
                break;
            default:
                console.log("Too much nesting in the field name ", field);
                return;
        }
    };

    return( 
        <Mutation
            mutation={mutation}
            update={(store, ret ) => {
                const newElement = {...ret.data[mutationResultName]};
                console.log('Inserting the new element', newElement);
                const data = store.readQuery({query: query, variables: variables }); 
                console.log(data);
                const index = data[queryName].items.findIndex( (item) => { return item.id === newElement.id } );
                if ( index === -1)
                {
                    console.log('Element not found in data', data);
                    data[queryName].items.push({...newElement});  
                    console.log('Element added to sdata', data);
                    store.writeQuery({ query: query, variables: variables, data} );
                }
                else{
                    console.log('Element found at index', index);
                    data[queryName].items[index] = {...newElement};
                    console.log('Element updated in data', data);
                    store.writeQuery({ query: query, variables: variables, data} );
                    console.log('Element ', newElement, ' already added to the list, so passing this time');
                }
                if (onClose)
                {
                    onClose();
                }
            }}
        >
            {(mutationCallBack, {loading, error}) => (
                    <React.Fragment>
                            {error && <p>{error.message}</p>}

                            <FormBuilder 
                                fieldsStructure={fieldsStructure} 
                                onChange={updateNewElement}
                                values={values}
                                buttons={
                                    <React.Fragment>
                                    {props.additionalButtons}
                                    {
                                        loading? <CircularProgress className={classes.progress} color="secondary" /> :
                                            <Tooltip title="Commit the changes" aria-label="Commit the changes">
                                                <Button onClick={e => {
                                                    e.preventDefault();
                                                    console.log('Calling the mutation ', mutation , ' with ' , values);
                                                    mutationCallBack({
                                                        variables: {
                                                            input: {
                                                                ...values
                                                            }
                                                        }
                                                    });
                                                }} 
                                                >
                                                    Commit
                                                    {
                                                        <Upload />
                                                    }
                                                </Button> 
                                            </Tooltip>
                                    }
                                    </React.Fragment>
                                }
                            />


                            
                            
                        </React.Fragment>
                )
            }       
        </Mutation>)
    
};


export const WithDialogMutationForm = withDialogbuttonNTooltip(MutationForm);

