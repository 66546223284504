

/* jshint esversion:8 */

import React, {Component, Suspense, lazy} from 'react';

import { Route, Switch } from 'react-router-dom';


// import {Menu, Icon, Dropdown, Button} from 'semantic-ui-react';

import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


import {Paper, Grid,} from '@material-ui/core';

import MapContainer from "./gmaps";

import {NavigationContext, MapContext} from './context'

const Listing = lazy ( () => (import('./Listing/Listing')) );

const styles = {
    paper: {
      //padding: '16px',
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    //   boxShadow: '0px 0px 10px 0px  #00000077',
    },
    fixedHeight: {
    //   height: '600px',
      maxHeight: '80vh',
      height: '80vh',
    },
    maxHeitgh500: {
        maxHeight: '600px',
    },
    
};

function Admin () {
    return <div>THIS IS Admin</div>;
}


class AppRoutesWithMap extends Component {
    
    constructor(props) {
        super(props);

        this.map = null;

        this.setMapRef = element => {
            // console.log("Setting the reference to ", element);
            this.mapController.mapContainer = element;
        };

        this.notifyListeners = () => {
            this.mapController.listeners.forEach( fn => {fn()} );
        }

        this.setMarkers = (markers) => {
            this.mapController.markers = [...markers];
            this.notifyListeners();
        };


        this.zoomToMarkers = (markers) => {
            this.mapController.zoomedMarkers = (markers && markers.length > 0 )? [...markers] : [...this.mapController.markers];
            this.notifyListeners();
        };

        this.getMapCenter = () => {
            const {longitude, latitude} = this.mapController.viewport;
            return {longitude: longitude, latitude: latitude};
        }

        this.updateViewport = (viewport) => {
            this.mapController.viewport = {
                ...this.mapController.viewport,
                ...viewport
            }
        }

        this.zoomToMarkers = this.zoomToMarkers.bind(this);
        this.setMarkers =  this.setMarkers.bind(this);
        this.getMapCenter = this.getMapCenter.bind(this);

        this.mapController = {
            markersZoomer: this.zoomToMarkers,
            markersSetter: this.setMarkers,
            coordsGetter: this.getMapCenter,
            updateViewport: this.updateViewport,
            addListener: (listener) => {this.mapController.listeners.add(listener) },
            removeListener: (listener) => {
                this.mapController.listeners.forEach(element => { 
                    (element === listener) && this.mapController.listeners.delete(element)
                });
            },
            listeners: new Set([]),
            markers: [],
            zoomedMarkers: [],
            mapRef: null,

            viewport: {
                longitude: 1,
                latitude: 1,
                zoom: 1.5,
                bearing: 0,
                pitch: 0
            }
        }



    }

    render() {

        const {classes} = this.props;
        const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

        const requireAuth = (nextState, replace) => {
            // if (!auth.isAdmin()) {
            if (false) {
                // Redirect to Home page if not an Admin
                replace({ pathname: '/' })
            }
        }

        const {match} = this.props;

        const isLargeDisplay = false;

        return (
            // <Grid doubling container divided stackable fluid>
                
                    <MapContext.Provider value={this.mapController}>
                    
                        <Grid
                            container spacing={3}
                            direction="row"
                            justify="space-evenly"
                            alignItems="stretch"
                        >

                        <Grid item xs={12} md={6} >
                        <Paper className={clsx( classes.fixedHeight, classes.paper)} >
                        <NavigationContext.Consumer>
                            {
                                ctxt => {

                                    return (
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <Switch>
                                                <Route 
                                                    path={`${match.path}/users/:id?`} 
                                                    render={ (props) => { ctxt.navigateTo(match.path + '/users'); return <Listing name='User' {...props} mapController={this.mapController}  />;  }  } 
                                                />
                                                <Route 
                                                    path={`${match.path}/farms/:id?`}
                                                    render={ (props) => {return <Listing name='Farm' {...props} mapController={this.mapController}  />;  }  } 
                                                />
                                                
                                                <Route 
                                                    path={`${match.path}/batches/:id?`}
                                                    render={ (props) => <Listing name='Batch' {...props} mapController={this.mapController}  /> } 
                                                />

                                                <Route 
                                                    path={`${match.path}/products/:id?`} 
                                                    render={ (props) => <Listing name='Product' {...props} mapController={this.mapController}  /> } 
                                                />
                                                <Route 
                                                    path={`${match.path}/comments/:id?`} 
                                                    render={ (props) => <Listing name='Comment' {...props} mapController={this.mapController}  /> } 
                                                />
                                                <Route path="/admin" component={Admin} onEnter={requireAuth} />
                                            </Switch>
                                        </Suspense>
                                    
                                    );
                                }
                            }

                        </NavigationContext.Consumer>
                        
                            </Paper>
                            
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* <Mapbox
                                    ref={this.setMapRef}
                                    // options={{
                                    //     latitude: 13.984873,
                                    //     longitude: -89.383360,
                                    //     bearing: 0,
                                    //     pitch: 0, 
                                    //     // width: 500, 
                                    //     // height: 500,
                                    //     zoom: 1.5 }}
                                /> */}
                                <Paper className={fixedHeightPaper}>
                                    <MapContainer context={this.mapController} />
                                </Paper>
                            </Grid>
                        </Grid>
                                                
                    
                    </MapContext.Provider>

        );
    }

};

export default withStyles(styles) (AppRoutesWithMap)