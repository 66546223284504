/* jshint esversion:8 */

import React from 'react';

import {ListItemLink} from './Generics/Controls';
import * as Generics from './Generics';

import {ListItemIcon, ListItemText} from '@material-ui/core';

const menuEntries = ['Dashboard','Users','Farms','Products','Batches','Comments'];
const secondaryMenuEntries = ['Settings', 'Dev'];

const createMenu = (entries) => (
  <div>
    {
      entries.map( entry => (
      <ListItemLink key={entry} to={Generics.getRouteUrl(entry)}>
      <ListItemIcon>
        {Generics.getIcon(entry)}
      </ListItemIcon>
      <ListItemText primary={entry} />
    </ListItemLink>) )
    }
  </div>
)


export const mainListItems = createMenu(menuEntries);

export const secondaryListItems = createMenu(secondaryMenuEntries);
