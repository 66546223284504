/* jshint esversion:8 */

import React, {Component} from 'react';

import {
  Item,
    // Header,
    Icon,
    Search,
    Message} from 'semantic-ui-react';

import { Help, Coffee, ViewDashboard, AccountMultiple, Tree, Basket, ClipboardText, MapMarkerRadius, CommentTextOutline, AlertCircleOutline, Translate, SettingsOutline, Tune } from 'mdi-material-ui';

import { Route } from 'react-router-dom';

import {NavigationContext} from '../context';


import _ from 'lodash';

import {Auth} from 'aws-amplify';

import awsconfig from '../../aws-exports';



const CloudFrontUrl = 'https://ddhdm8564q7b8.cloudfront.net';

export const getS3Url = (s3object, width=null, height=null) => {
    if (s3object){
        // var url = s3object.thumbnail? s3object.thumbnail : s3object.url;
        var url = s3object.url;
        if (height && width){
            const imageRequest = JSON.stringify({
                bucket: awsconfig.aws_user_files_s3_bucket,
                key: s3object.url.replace('https://masdar001-ayadee.s3.amazonaws.com/','') ,
                edits: {
                    resize: {
                        width: width,
                        height: height,
                        // fit: 'sharp.fit.cover',
                        // position: 'sharp.strategy.entropy'
                      }
                }
            });
            // console.log(imageRequest);
            url = `${CloudFrontUrl}/${btoa(imageRequest)}`;
        }
        return url;
    }
    return 'https://react.semantic-ui.com/images/wireframe/image.png';
}


// const getResults = () =>   _.times(5, () => ({     title:
// faker.company.companyName(),     description: faker.company.catchPhrase(),
// image: faker.internet.avatar(),     price: faker.finance.amount(0, 100, 2,
// '$'),   })) const source = _.range(0, 3).reduce((memo) => {   const name =
// faker.hacker.noun()   // eslint-disable-next-line no-param-reassign
// memo[name] = {     name,     results: getResults(),   }   return memo }, {})

const initialState = {
    isLoading: false,
    results: [],
    value: ''
};

export class SearchField extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleResultSelect = (e, {result}) => this.setState({value: result.title})

    handleSearchChange = (e, {value}) => {
        this.setState({isLoading: true, value})

        setTimeout(() => {
            if (this.state.value.length < 1) 
                return this.setState(initialState)

            const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
            const isMatch = result => re.test(result.name)

            this.setState({
                isLoading: false,
                results: _.filter(this.props.source, isMatch)
            })
        }, 300)
    }

    render() {
        const {isLoading, value, results} = this.state

        return (
            <Search
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {leading: true})}
                results={results}
                value={value}
                {...this.props.options}/>

        )
    }
}

export const NotifMessage = (props) => (
    <Message icon>
        <Icon name='circle notched' loading/>
        <Message.Content>
            <Message.Header>{props.header}</Message.Header>
            {props.text}
        </Message.Content>
    </Message>
);

export class LoggerFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries : []
    }
  }

  addEvent(e) {
    let newEntries = this.state.entries;
    newEntries.push(e);
    this.setState({entries: newEntries});
  }

  render(){
    const {entries} = this.state;
    return(
      <Item.Group>
        {entries.map( entry => <Item>
        <Item.Image size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' />
        <Item.Content>
          <Item.Header as='a'>entry.header</Item.Header>
          <Item.Meta>Description</Item.Meta>
          <Item.Description>
            {entry.description}
          </Item.Description>
          {/* {
            entry.extra && <Item.Extra>entry.extra</Item.Extra> 
          } */}
        </Item.Content>
      </Item> )}
      </Item.Group>
    )
  }

}



// Higher Order Component that adds routing/navigation function
export function withRouter (WrappedComponent) {
    return class extends React.Component {
        render() {
            const {to} = this.props;
            return (
                <NavigationContext.Consumer>
                    {
                        (ctxt) =>{
                            // console.log('state', ctxt);
                            return  <Route render={({ history}) => 
                            (<WrappedComponent {...this.props} onClick={() => { if (to) { history.push(to); ctxt.navigateTo(to);} }}/> ) }/>
                        }     
                        
                    }    
                </NavigationContext.Consumer>
            )
        }
    }
}

export class NavigationButton extends React.Component {
    render()
    {
        // console.log('RoutedButton', this.props);
        // console.log(this.context);
        const navigateTo = this.context.navigateTo;
        const {to, icon, text} = this.props;
        return(
        
            <Route render={({ history}) => (
                <div className="navigationButton"
                onClick={() => { if (to) { history.push(to); navigateTo(to);} }}
                {...this.props}
                >
                    <Icon className='icon' name={icon} />
                    {/* <img src={image} alt="Avatar" class="image"/> */}
                    <div className="overlay">
                        <div className="text">{text}</div>
                    </div>
                </div>

          )} />);
    }
    
}
NavigationButton.contextType =  NavigationContext;




export const getIcon  = (element) => {
    switch(element.toLowerCase()){
        case 'dashboard':
        case 'home': return <ViewDashboard/> ;
        case 'users':
        case 'user': return <AccountMultiple/> ;
        case 'farms':
        case 'farm': return  <Tree/> ;
        case 'batches':
        case 'batch': return <Basket/> ;
        case 'comments':
        case 'comment': return <CommentTextOutline/>;
        case 'position': return <MapMarkerRadius/>;
        case 'products':
        case 'product': return <Coffee/>;
        case 'reports':
        case 'report': return <ClipboardText/>;
        case 'alert': return <AlertCircleOutline />;
        case 'language':
        case 'languages':
            return <Translate/>;
        case 'dev':
            return <SettingsOutline/>;
        case 'settings':
            return <Tune/>;
        default: return <Help/>;
    }
}


export const getIconName  = (element) => {
    switch(element.toLowerCase()){
        case 'home': return 'grid';
        case 'user': return 'user';
        case 'farm': return 'tree';
        case 'batch': return 'cart';
        case 'comment': return 'camera';
        case 'position': return 'marker';
        case 'product': return 'coffee';
        default: return 'question';
    }
}

export const getRouteUrl = (element) => {
    
    if (!element) return '#';

    switch(element.toLowerCase()){
        case 'dashboard':
        case 'home': return '/';
        case 'users':
        case 'user': return '/g/users';
        case 'farms':
        case 'farm': return '/g/farms';
        case 'batches':
        case 'batch': return '/g/batches';
        case 'comments':
        case 'comment': return '/g/comments';
        case 'products':
        case 'product': return '/g/products';
        case 'settings': return '/settings';
        case 'dev': return '/dev';
        default: return '#';
    }
}


export async function isUserAuthenticated() {
    return await Auth.currentAuthenticatedUser()
      .then(() => { return true; })
      .catch(() => { return false; });
}

export const getCurrentUserName  = (default_user = null) => {
    return (Auth.user && Auth.user.name) ? Auth.user.name : default_user;
}

export const getPublicUrl = (id) => ( `https://batches.ayadee.io/${id}` )

