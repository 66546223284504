/* jshint esversion:8 */

import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppRoutesWithMap from './routes';
import Settings from './Settings';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Listing from './Listing/Listing';

import {CssBaseline, Drawer, AppBar, Toolbar, List,
Typography,
Divider,
Button,
IconButton,
Badge,
Container,
Grid,
Paper,
Link ,
useMediaQuery,
}  from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';

import {NavigationContext} from './context';

import {Auth} from 'aws-amplify';
import DevWindow from './dev';



function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Built with love by '}
      <Link color="inherit" href="https://ayadee.io">
        Ayadee Tech
      </Link>
      <br/>
      <Link color="inherit"  href="https://www.freepik.com/free-photos-vectors/background">Thanks www.freepik.com for the awsome work</Link>
    </Typography>
  );
}

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: '1'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${theme.spacing(7)}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  
}));

function Home() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits />
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders />
              </Paper>
            </Grid>
          </Grid>
  );
}

const Title = (props) => {
  const classes = useStyles();
  return (
    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
      {props.text}
    </Typography>
  );
}


export const Main = (props) => {
  return (
    <Router>
      <Route path='/' component={Dashboard} />
    </Router>
  );
}

export const Dashboard = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isLargeDisplay = useMediaQuery(theme.breakpoints.up('sm'));
  const showWideMenu = isLargeDisplay && open;

  const {match} = props;


  // We need an XOR implementation http://www.howtocreate.co.uk/xor.html
  const showSmallMenu = (open !==  isLargeDisplay);
  const hideMenu = (!isLargeDisplay && !open);

  const [title, setTitle] = React.useState('Dashboard');
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigationContext = {
    activePage: 'home',
    navigateTo: null,
  };

  const navigateTo = (url) => {
    var page = url.split('/').filter(function(el) {return el.length !== 0})[0];
    console.log('navigate to called', url);
    // console.log(page);

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    if (!page) page='home';

    navigationContext.title =  capitalizeFirstLetter(page);
    navigationContext.activePage = page;
    navigationContext.url =  url;
    setTitle(title);
  };
  navigationContext.navigateTo = navigateTo;

  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavigationContext.Provider value={navigationContext}>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Title text={title} />
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Button variant="outlined" color="inherit" onClick={() => Auth.signOut() } >Logout</Button>
        </Toolbar>
      </AppBar>
      {
        !hideMenu && <Drawer
          variant="permanent"
          classes={{
            paper: clsx( (showWideMenu || showSmallMenu) && classes.drawerPaper, showSmallMenu && classes.drawerPaperClose ),
          }}
          open={showWideMenu}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer>
      }
      
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route exact path={`/`} component={Home} />
            <Route path={`/g`} component={AppRoutesWithMap} />
            <Route path={`/settings`} component={Settings} />
            <Route path={`/dev`} component={DevWindow} />
          </Switch>
        </Container>
        <MadeWithLove />
      </main>
      </NavigationContext.Provider>
    </div>
  );
}
