/* jshint esversion:8 */

import React from 'react';


import { createMuiTheme } from '@material-ui/core/styles';


//AppSync and Apollo libraries
import AWSAppSyncClient,  { AUTH_TYPE } from "aws-appsync";
import {Rehydrated} from 'aws-appsync-react';
import {ApolloProvider} from 'react-apollo';

//Amplify
import Amplify, {Auth} from 'aws-amplify';
import {withAuthenticator} from 'aws-amplify-react';

// Components

import { ThemeProvider } from '@material-ui/styles';
import {themes} from './theme';
import {SettingsContext} from './Components/context';

import {Main} from './Components/Dashboard';

import awsconfig from './aws-exports';


// Amplify init
Amplify.configure(awsconfig);
console.log("Configuring AWS with ", awsconfig);

const GRAPHQL_API_REGION = awsconfig.aws_appsync_region
const GRAPHQL_API_ENDPOINT_URL = awsconfig.aws_appsync_graphqlEndpoint
const S3_BUCKET_REGION = awsconfig.aws_user_files_s3_bucket_region
const S3_BUCKET_NAME = awsconfig.aws_user_files_s3_bucket
// const AUTH_TYPE = awsconfig.aws_appsync_authenticationType

// AppSync client instantiation
const client = new AWSAppSyncClient({
    url: GRAPHQL_API_ENDPOINT_URL,
    region: GRAPHQL_API_REGION,
    auth: {

        type: AUTH_TYPE.API_KEY, // or type: awsconfig.aws_appsync_authenticationType,
        apiKey: awsconfig.aws_appsync_apiKey,


        // type: AUTH_TYPE,
        // // Get the currently logged in users credential.
        // jwtToken: async() => (await Auth.currentSession())
        //     .getAccessToken()
        //     .getJwtToken()
    },
    disableOffline: true,
    // cacheOptions: {
    //     dataIdFromObject: (obj) => {
    //       let id = defaultDataIdFromObject(obj);
    
    //       if (!id) {
    //         const { __typename: typename } = obj;
    //         switch (typename) {
    //           case 'Comment':
    //             return `${typename}:${obj.commentId}`;
    //           default:
    //             return id;
    //         }
    //       }
    
    //       return id;
    //     }
    // },
      
    Storage: {
        bucket: S3_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket
        region: S3_BUCKET_REGION, //OPTIONAL -  Amazon service region
    },
    // Amplify uses Amazon IAM to authorize calls to Amazon S3. This provides the
    // relevant IAM credentials.
    complexObjectsCredentials: () => Auth.currentCredentials()
});

const App = (props) => {

    const readValue =  localStorage.settings? JSON.parse(localStorage.settings): {themeIndex: 0};
    console.log('Read settings are: ', readValue);
    const [settings, setSettings] = React.useState(readValue);

    React.useEffect(() => {
        console.log('Saving the settings: ', settings);
        localStorage.setItem('settings', JSON.stringify(settings));
      }, [settings]);

  return (
    <SettingsContext.Provider value={[settings,setSettings]}>
        <ThemeProvider theme={createMuiTheme(themes[settings.themeIndex])}>
            <Main/>
        </ThemeProvider>
  </SettingsContext.Provider>
  );
}


// const styleLink = document.createElement("link");
// styleLink.rel = "stylesheet";
// styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
// document.head.appendChild(styleLink);

const AppWithAuth = withAuthenticator(App,{
    // Render a sign out button once logged in
    includeGreetings: false, 
    // Show only certain components
    // authenticatorComponents: [MyComponents],
    // display federation/social provider buttons 
    // federated: {myFederatedConfig}, 
    // customize the UI/styling
    // theme: {myCustomTheme}
    } 

);

export default() => (
    <ApolloProvider client={client}>
        <Rehydrated>
            <AppWithAuth/>
        </Rehydrated> 
    </ApolloProvider>
);
