// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:040021f7-88cb-4f3b-953d-d10319658c24",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_rW0z5Qshp",
    "aws_user_pools_web_client_id": "p48ah4d1scad12rme01pskcj5",
    "oauth": {},
    "aws_user_files_s3_bucket": "masdar001-ayadee",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://lb56bnkch5dbrgdsb4vpqxhwsa.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    // "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    // "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2jnwuks6zrcblpigx2oeoi2vea",
    "aws_content_delivery_bucket": "masdar-20190614142918-hostingbucket-ayadee",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://masdar-20190614142918-hostingbucket-ayadee.s3-website.eu-central-1.amazonaws.com"
};


export default awsmobile;
