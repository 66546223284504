// eslint-disable
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    role
    languages
    avatar {
      url
      thumbnail
    }
    createdAt
    updatedAt
    farms {
      items {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    comments {
      items {
        id
        comment
        stage
        company {
          id
          name
          services
          locations
          logo {
            url
            thumbnail
          }
          createdAt
          updatedAt
        }
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batch {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    store
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    nextToken
  }
}
`;
export const getFarm = `query GetFarm($id: ID!) {
  getFarm(id: $id) {
    id
    name
    description
    region
    position {
      longitude
      latitude
      altitude
    }
    createdAt
    updatedAt
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const listFarms = `query ListFarms(
  $filter: ModelFarmFilterInput
  $limit: Int
  $nextToken: String
) {
  listFarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      region
      position {
        longitude
        latitude
        altitude
      }
      createdAt
      updatedAt
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      photos {
        url
        thumbnail
      }
    }
    nextToken
  }
}
`;
export const getBatch = `query GetBatch($id: ID!) {
  getBatch(id: $id) {
    id
    name
    product {
      id
      name
      icon {
        url
        thumbnail
      }
      picture {
        url
        thumbnail
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      stages {
        items {
          id
          name
          description
          order
          product {
            id
            name
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
    }
    createdAt
    updatedAt
    farm {
      id
      name
      description
      region
      position {
        longitude
        latitude
        altitude
      }
      createdAt
      updatedAt
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      photos {
        url
        thumbnail
      }
    }
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    comments {
      items {
        id
        comment
        stage
        company {
          id
          name
          services
          locations
          logo {
            url
            thumbnail
          }
          createdAt
          updatedAt
        }
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batch {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
    parent {
      id
      name
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      createdAt
      updatedAt
      farm {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      parent {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      children {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
    }
    children {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;
export const listBatchs = `query ListBatchs(
  $filter: ModelBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  listBatchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      createdAt
      updatedAt
      farm {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      parent {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      children {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getComment = `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    comment
    stage
    company {
      id
      name
      services
      locations
      logo {
        url
        thumbnail
      }
      createdAt
      updatedAt
    }
    position {
      longitude
      latitude
      altitude
    }
    createdAt
    updatedAt
    creator {
      id
      name
      role
      languages
      avatar {
        url
        thumbnail
      }
      createdAt
      updatedAt
      farms {
        items {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      store
    }
    batch {
      id
      name
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      createdAt
      updatedAt
      farm {
        id
        name
        description
        region
        position {
          longitude
          latitude
          altitude
        }
        createdAt
        updatedAt
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        photos {
          url
          thumbnail
        }
      }
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      comments {
        items {
          id
          comment
          stage
          company {
            id
            name
            services
            locations
            createdAt
            updatedAt
          }
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batch {
            id
            name
            createdAt
            updatedAt
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
      parent {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      children {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const listComments = `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      comment
      stage
      company {
        id
        name
        services
        locations
        logo {
          url
          thumbnail
        }
        createdAt
        updatedAt
      }
      position {
        longitude
        latitude
        altitude
      }
      createdAt
      updatedAt
      creator {
        id
        name
        role
        languages
        avatar {
          url
          thumbnail
        }
        createdAt
        updatedAt
        farms {
          items {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          nextToken
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        store
      }
      batch {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      photos {
        url
        thumbnail
      }
    }
    nextToken
  }
}
`;
export const getProduct = `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    name
    icon {
      url
      thumbnail
    }
    picture {
      url
      thumbnail
    }
    batches {
      items {
        id
        name
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        createdAt
        updatedAt
        farm {
          id
          name
          description
          region
          position {
            longitude
            latitude
            altitude
          }
          createdAt
          updatedAt
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          batches {
            nextToken
          }
          photos {
            url
            thumbnail
          }
        }
        creator {
          id
          name
          role
          languages
          avatar {
            url
            thumbnail
          }
          createdAt
          updatedAt
          farms {
            nextToken
          }
          batches {
            nextToken
          }
          comments {
            nextToken
          }
          store
        }
        comments {
          items {
            id
            comment
            stage
            createdAt
            updatedAt
          }
          nextToken
        }
        parent {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        children {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    stages {
      items {
        id
        name
        description
        order
        product {
          id
          name
          icon {
            url
            thumbnail
          }
          picture {
            url
            thumbnail
          }
          batches {
            nextToken
          }
          stages {
            nextToken
          }
        }
        photos {
          url
          thumbnail
        }
      }
      nextToken
    }
  }
}
`;
export const listProducts = `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      icon {
        url
        thumbnail
      }
      picture {
        url
        thumbnail
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      stages {
        items {
          id
          name
          description
          order
          product {
            id
            name
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getStage = `query GetStage($id: ID!) {
  getStage(id: $id) {
    id
    name
    description
    order
    product {
      id
      name
      icon {
        url
        thumbnail
      }
      picture {
        url
        thumbnail
      }
      batches {
        items {
          id
          name
          product {
            id
            name
          }
          createdAt
          updatedAt
          farm {
            id
            name
            description
            region
            createdAt
            updatedAt
          }
          creator {
            id
            name
            role
            languages
            createdAt
            updatedAt
            store
          }
          comments {
            nextToken
          }
          parent {
            id
            name
            createdAt
            updatedAt
          }
          children {
            nextToken
          }
        }
        nextToken
      }
      stages {
        items {
          id
          name
          description
          order
          product {
            id
            name
          }
          photos {
            url
            thumbnail
          }
        }
        nextToken
      }
    }
    photos {
      url
      thumbnail
    }
  }
}
`;
export const listStages = `query ListStages(
  $filter: ModelStageFilterInput
  $limit: Int
  $nextToken: String
) {
  listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      order
      product {
        id
        name
        icon {
          url
          thumbnail
        }
        picture {
          url
          thumbnail
        }
        batches {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        stages {
          items {
            id
            name
            description
            order
          }
          nextToken
        }
      }
      photos {
        url
        thumbnail
      }
    }
    nextToken
  }
}
`;
export const getCompany = `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    name
    services
    locations
    logo {
      url
      thumbnail
    }
    createdAt
    updatedAt
  }
}
`;
export const listCompanys = `query ListCompanys(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      services
      locations
      logo {
        url
        thumbnail
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
