/* jshint esversion:8 */

import React from 'react';



// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
export const NavigationContext = React.createContext({
    page: 'home',
    url: '/',
    navigateTo: (page,url) => {},
  }
);


export const MapContext = React.createContext({
    markers: [],
    zoomedMarkers: [],
    viewport: {
        longitude: 0,
        latitude: 0,
        zoom: 1,
        bearing: 0,
        pitch: 0,
    },
    markersZoomer:  (markers) => {} ,
    markersSetter: (markers) => {},
    coordsGetter: () => {},
    updateViewport: (viewport) => {},
    addListener: (listener) => {},
    removeListener: (listener) => {},
}
);

export const SettingsContext = React.createContext ([{themeIndex: 0,datesIllustration: 'fuzzy'},(name, value) => {}]);





